.userProfileContainer {
    @apply inline-block mobile:absolute mobile:-top-[49px] mobile:right-0;
}
.userProfile {
    @apply relative flex items-center px-4 py-0 pt-[3px] mobile:fixed mobile:right-[18px];
}
.userProfile .avatar {
    @apply flex h-[32px] w-[32px] items-center justify-center overflow-hidden rounded-full bg-interface-400 text-base text-common-white;
}
.userProfile .userInfo {
    @apply ml-2;
}
.userProfile .userInfo p {
    @apply mb-0 text-sm text-common-black;
}
.userProfile .userInfo span {
    @apply text-sm text-gray-500;
}

@font-face {
    font-family: 'Redacted-Regular';
    src: url('/fonts/redacted.regular.eot');
    src: url('/fonts/redacted.regular.eot') format('embedded-opentype'),
        url('/fonts/redacted.regular.ttf') format('truetype'),
        url('/fonts/redacted.regular.woff') format('woff'),
        url('/fonts/redacted.regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.errorPage {
    @apply fixed w-full h-screen;
}
.errorPage .inner {
    @apply flex items-center justify-center w-full h-screen;
}
.errorPage .heading {
    @apply text-[120px] font-acumin-bold text-primary-500 mb-16;
}
.errorPage .subHeading {
    @apply text-4xl;
}
.errorPage .headingDescp {
    @apply text-2xl;
}
.errorPage .description {
    @apply text-lg text-interface-900 max-w-[700px] mx-auto mb-0;
}
.mapPosition {
    @apply flex items-center justify-center w-full h-screen;
}
.aeroplance {
    @apply absolute top-[-170px] left-0 right-[-140px] mx-auto w-[350px] hidden;
}

.checkBox {
    @apply relative flex pl-0 text-sm text-common-black;
}
.checkBox input {
    @apply absolute top-0 left-0 w-full h-full opacity-0 appearance-none border-interface-300 focus:border-interface-300 focus:shadow-none focus:ring-0;
}
.checkBox .checkmark {
    @apply relative top-0.5 mr-2 h-[16px] w-[16px] flex-shrink-0 rounded border border-interface-300;
}
.checkBox .checkmark i {
    @apply absolute  left-[1.5px] top-[2.5px] hidden text-[9px] text-common-white;
}
.checkBox input:checked + .checkmark {
    @apply border-primary-500 bg-primary-500;
}
.checkBox .checkmarkcircle i {
    @apply absolute  left-[2.7px] top-[3px] hidden text-[7px] text-common-white;
}
.checkBox input:checked + .checkmark i {
    @apply block;
}
.checkBox .checkmarkcircle {
    @apply relative top-1 mr-2 h-[16px] w-[16px] flex-shrink-0 rounded-full border border-interface-300;
}
.checkBox input:checked + .checkmarkcircle {
    @apply border-primary-500 bg-primary-500;
}
.checkBox input:checked + .checkmarkcircle i {
    @apply block;
}
/* disabled */
.checkBox.disabled,
.checkBox input[disabled]{
    @apply cursor-not-allowed opacity-50;
}
.checkBox input[disabled] + span + span {
    @apply cursor-not-allowed  opacity-50;
}
.checkBox input:checked[disabled] + .checkmark,
.checkBox input:checked[disabled] + .checkmarkcircle {
    @apply cursor-not-allowed opacity-50;
}

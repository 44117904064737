.logsBlock {
    @apply fixed left-[auto] top-0 right-0 z-50 h-screen w-[384px] bg-common-white p-4 pr-0 shadow;
}
.activityBlock {
    @apply top-0 right-0 left-auto;
}
.title {
    @apply mb-6;
}
.items {
    @apply relative pr-4;
}
.item {
    @apply relative block cursor-pointer last-of-type:mb-0 last-of-type:border-none last-of-type:pb-0;
}
.image {
    @apply absolute top-0 left-0 text-xl text-interface-200;
}
.itemactive i {
    @apply text-primary-500;
}
.heading {
    @apply mb-1 w-full pl-7 text-sm;
}
.description {
    @apply mb-3 flex w-full border-b border-dashed border-interface-200 pb-3 pl-7;
}
.item:last-child .description {
    @apply mb-0 border-none pb-0;
}
.time {
    @apply flex-shrink-0 pl-2 text-interface-600;
}
.btnClose {
    @apply absolute top-0 right-0 hidden cursor-pointer rounded-full bg-body-bg text-center shadow;
}
li.item:hover .btnClose {
    @apply block;
}
li.item:hover .time {
    @apply opacity-0;
}
.backDrop {
    @apply pointer-events-none fixed top-0 right-0 z-30 h-full w-full bg-common-black opacity-60;
}
.activityBackDrop {
    @apply left-0 right-auto;
}

.authMain {
    @apply inline-block w-full h-screen;
}
.authLeft {
    @apply relative inline-block w-[480px] bg-common-white align-top ipad:w-full;
}
.authContainer {
    @apply flex flex-col justify-between w-full h-screen ml-0 mr-auto;
}
.authContainer .card {
    @apply w-full mb-8 border-0;
}
.authContainer .card .cardBody {
    @apply border-0;
}
.authContainer .card .logo {
    @apply ml-16 w-[80px] pt-10 mobile:ml-12;
}
.authContainer .cardBody .textDescription {
    @apply mb-8 text-interface-700;
}
.authContainer .cardBody .linkDescription {
    @apply mb-4 mr-2 font-medium;
}
.authFooter {
    @apply w-full py-5 mx-auto text-sm text-center text-interface-600;
}
.authRight {
    @apply -mb-2 inline-block h-full w-[calc(100%-480px)] bg-cover ipad:hidden;
}
/* @media only screen and (max-height: 772px) and (orientation: landscape) {
    .authContainer {
        @apply block;
    }
    .authMain {
        @apply bg-common-white;
    }
}
@media only screen and (max-width: 460px) and (orientation: portrait) {
    .authMain {
        @apply h-full;
    }
    .authContainer .cardBody .logo {
        @apply relative left-[0];
    }
    .authContainer .authFooter {
        @apply relative bottom-[0] top-[20px];
    }
} */

@font-face {
    font-family: 'acumin';
    src: url('/fonts/AcuminProSemiCond-Regular.eot');
    src: url('/fonts/AcuminProSemiCond-Regular.eot?#iefix')
            format('embedded-opentype'),
        url('/fonts/AcuminProSemiCond-Regular.woff2') format('woff2'),
        url('/fonts/AcuminProSemiCond-Regular.woff') format('woff'),
        url('/fonts/AcuminProSemiCond-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'acumin-bold';
    src: url('/fonts/AcuminProSemiCond-Semibold.eot');
    src: url('/fonts/AcuminProSemiCond-Semibold.eot?#iefix')
            format('embedded-opentype'),
        url('/fonts/AcuminProSemiCond-Semibold.woff2') format('woff2'),
        url('/fonts/AcuminProSemiCond-Semibold.woff') format('woff'),
        url('/fonts/AcuminProSemiCond-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
/* signature fonts */
@font-face {
    font-family: 'hurricane';
    src: url('/fonts/signature-fonts/hurricane.woff2') format('woff2'),
        url('/fonts/signature-fonts/hurricane.ttf') format('truetype');
}
@font-face {
    font-family: 'lobster';
    src: url('/fonts/signature-fonts/lobster.woff2') format('woff2'),
        url('/fonts/signature-fonts/lobster.ttf') format('truetype');
}
@font-face {
    font-family: 'playball';
    src: url('/fonts/signature-fonts/playball.woff2') format('woff2'),
        url('/fonts/signature-fonts/playball.ttf') format('truetype');
}
@font-face {
    font-family: 'smooch';
    src: url('/fonts/signature-fonts/smooch.woff2') format('woff2'),
        url('/fonts/signature-fonts/smooch.ttf') format('truetype');
}
@font-face {
    font-family: 'arial-narrow';
    src: url('/fonts/signature-fonts/arial-narrow.woff2') format('woff2'),
        url('/fonts/signature-fonts/arial-narrow.ttf') format('truetype');
}
@font-face {
    font-family: 'calibri-regular';
    src: url('/fonts/signature-fonts/calibri-regular.woff2') format('woff2'),
        url('/fonts/signature-fonts/calibri-regular.ttf') format('truetype');
}
@font-face {
    font-family: 'courier';
    src: url('/fonts/signature-fonts/courier.woff2') format('woff2'),
        url('/fonts/signature-fonts/courier.ttf') format('truetype');
}
@font-face {
    font-family: 'garamond-regular';
    src: url('/fonts/signature-fonts/garamond-regular.woff2') format('woff2'),
        url('/fonts/signature-fonts/garamond-regular.ttf') format('truetype');
}
@font-face {
    font-family: 'georgia';
    src: url('/fonts/signature-fonts/georgia.woff2') format('woff2'),
        url('/fonts/signature-fonts/georgia.ttf') format('truetype');
}
@font-face {
    font-family: 'helvetica';
    src: url('/fonts/signature-fonts/helvetica.woff2') format('woff2'),
        url('/fonts/signature-fonts/helvetica.ttf') format('truetype');
}
@font-face {
    font-family: 'lucida-console';
    src: url('/fonts/signature-fonts/lucida-console.woff2') format('woff2'),
        url('/fonts/signature-fonts/lucida-console.ttf') format('truetype');
}
@font-face {
    font-family: 'ms-gothic';
    src: url('/fonts/signature-fonts/ms-gothic.woff2') format('woff2'),
        url('/fonts/signature-fonts/ms-gothic.ttf') format('truetype');
}
@font-face {
    font-family: 'ms-mincho';
    src: url('/fonts/signature-fonts/ms-mincho.woff2') format('woff2'),
        url('/fonts/signature-fonts/ms-mincho.ttf') format('truetype');
}
@font-face {
    font-family: 'tahoma';
    src: url('/fonts/signature-fonts/tahoma.woff2') format('woff2'),
        url('/fonts/signature-fonts/tahoma.ttf') format('truetype');
}
@font-face {
    font-family: 'times-new-roman';
    src: url('/fonts/signature-fonts/times-new-roman.woff2') format('woff2'),
        url('/fonts/signature-fonts/times-new-roman.ttf') format('truetype');
}
@font-face {
    font-family: 'trebuchet';
    src: url('/fonts/signature-fonts/trebuchet.woff2') format('woff2'),
        url('/fonts/signature-fonts/trebuchet.ttf') format('truetype');
}
@font-face {
    font-family: 'verdana';
    src: url('/fonts/signature-fonts/verdana.woff2') format('woff2'),
        url('/fonts/signature-fonts/verdana.ttf') format('truetype');
}

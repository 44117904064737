.navigation {
    @apply relative mt-2 inline-block align-top;
}
.navigation ul {
    @apply relative flex items-center;
}
.navigation ul li {
    @apply mx-[20px] text-base last-of-type:mr-0 mobile:mx-2;
}
.navigation ul li a {
    @apply block px-[6px] pb-[8px] pt-0 text-sm text-interface-600 mobile:px-[2px] mobile:text-xs;
}
.navigation ul li a.active {
    @apply relative font-acumin-bold text-common-black;
}
.navigation ul li a.active::after {
    content: '';
    @apply absolute bottom-[-13px] left-0 h-[2px] w-full bg-primary-500;
}
.navigation ul li .count {
    @apply absolute top-[-6px] right-[-9px] flex h-[16px] w-[16px] items-center justify-center rounded-full bg-primary-500 text-xs text-common-white mobile:h-3 mobile:w-3;
}
.navigation ul li .count span {
    @apply absolute top-[0px] left-[0px] right-0 bottom-0 m-auto flex items-center justify-center text-[12px] mobile:text-[10px];
}
.navigation ul li a.disabled {
    @apply pointer-events-none text-interface-300;
}
.disabled {
    @apply pointer-events-none text-interface-300;
}

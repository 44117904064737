.inputModule {
    @apply relative;
}
.formGroup {
    @apply relative mb-4;
}
.darkSearchbox .formGroup {
    @apply mb-0;
}
.noSpace {
    margin-bottom: 0 !important;
}
.formControl {
    @apply h-[42px] w-full rounded-md border border-interface-300 px-3 text-sm text-common-black placeholder:text-interface-600 focus:border-interface-300 focus:shadow-none focus:outline-none;
}
.formControl.md {
    @apply h-[36px];
}
.formControl.withoutBorder {
    @apply bg-transparent border-none shadow-none focus:shadow-none;
}
.formControl.hasError,
.isFormcontrolError .formControl.hasError {
    @apply border-danger-600 !important;
}
.uploadError .formControl {
    @apply border-danger-600;
}
.formControl.dark {
    @apply border border-interface-500 bg-interface-600 text-common-white;
}
.form-control.dark i {
    @apply text-interface-200;
}
textarea.formControl {
    @apply block w-full h-auto p-3 resize-none;
}
.formControl.highlighted {
    @apply bg-interface-50 text-interface-900;
}
.formControl.disabled {
    @apply pointer-events-none opacity-70;
}
.no-form-space {
    @apply mb-0;
}
.datetime {
    @apply relative inline-block w-full;
}
.datetime .form-control {
    @apply w-full;
}
.dataPickerRange .startDate .formControl.sm,
.dataPickerRange .endDate .formControl.sm {
    @apply h-9;
}
.phoneInput {
    @apply relative;
}
.phoneInput .flagIcon {
    @apply absolute bottom-0 text-lg left-3 top-9;
}
.datetime .icon-calendar {
    @apply relative top-[-10px];
}
.dataPickerRange {
    @apply flex;
}
.dataPickerRange .startDate,
.dataPickerRange .endDate {
    @apply w-2/4;
}
.dataPickerRange .startDate .formControl {
    @apply rounded-tr-none rounded-br-none;
}
.dataPickerRange .endDate .formControl {
    @apply ml-[-1px] rounded-tl-none rounded-bl-none;
}
.searchInput {
    @apply relative;
}
.searchInput input {
    @apply mb-0;
}
.form-control .searchInput i {
    @apply text-sm;
}
.darkSearchbox .searchInput i {
    @apply text-interface-200;
}
.thumnailWrapper {
    @apply flex gap-4 mb-4;
}
.thumnail {
    @apply relative;
}
.configuration-thumnail.configuration-thumnail .thumnail {
    @apply mb-0;
}
.thumnail span {
    @apply overflow-hidden rounded-lg;
}
.deleteImg {
    @apply absolute flex items-center justify-center w-5 h-5 rounded-full cursor-pointer right-2 top-2 bg-common-white text-primary-500;
}
.filePreviewAside {
    @apply flex w-full gap-3;
}
.filePreviewAside .formGroup {
    @apply w-full;
}
.testing.testing .formGroup {
    @apply mb-0;
}
.thumbnailimage {
    @apply object-cover w-full h-full;
}
.customDurationPicker {
    @apply z-30 overflow-visible;
}
.icon-section {
    @apply absolute top-0 right-0 flex items-center justify-center h-full px-3 pointer-events-none;
}
.customDurationPicker input {
    letter-spacing: 3px;
    @apply relative top-0 left-0 h-[40px] w-[34px] min-w-0 text-center  outline-none;
}
.durationDropdown {
    @apply absolute top-[45px]  left-[-1px] h-[150px] w-[115px] rounded-md border border-interface-200 bg-common-white text-center shadow;
}
.dropdownItems li {
    @apply cursor-pointer py-1.5 text-sm;
}
.dropdownItems .selected,
.dropdownItems .active {
    @apply font-semibold bg-interface-200 text-common-black;
}

@tailwind base;
@tailwind components;
/* ================
    Custom
=================== */
html {
    @apply font-themeFont font-normal;
}
body {
    @apply overflow-x-hidden bg-gray-50 text-sm text-common-black;
}
.containers {
    @apply p-6;
}
.text-xxs {
    font-size: 10px;
}
.container-sm {
    @apply relative mx-auto max-w-[920px];
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    @apply text-interface-600;
}
input:-ms-input-placeholder,
textarea::-ms-input-placeholder {
    /* IE 10+ */
    @apply text-interface-600;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
    /* Firefox 19+ */
    @apply text-interface-600;
}
/* Chrome, Safari, Edge, Opera */
input:-webkit-outer-spin-button,
input:-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
p {
    @apply mb-4;
}
a {
    @apply text-common-black;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    @apply mb-4 font-acumin-bold;
}
h1,
.h1 {
    @apply border-opacity-100 text-3xl;
}
.h2,
h2 {
    @apply text-2xl;
}
.h3,
h3 {
    @apply text-xl;
}
.h4,
h4 {
    @apply text-lg;
}
.h5,
h5 {
    @apply text-base;
}
.h6,
h6 {
    @apply text-sm;
}
ul {
    @apply relative;
}
ol li {
    @apply list-inside list-decimal text-sm;
}
.scrollable {
    @apply overflow-y-auto;
}
.scrollable::-webkit-scrollbar-track,
.css-iqtl29-MenuList::-webkit-scrollbar-track {
    @apply rounded-[50px] bg-common-white;
}
.scrollable::-webkit-scrollbar,
.css-iqtl29-MenuList::-webkit-scrollbar {
    @apply h-[6px] w-[4px] rounded-[50px] bg-common-white;
}
.scrollable::-webkit-scrollbar-thumb,
.css-iqtl29-MenuList::-webkit-scrollbar-thumb {
    @apply rounded-[50px] bg-interface-100;
}
/* ============= notification-scrollbar ============= */
.btnsmall {
    @apply text-sm !important;
}
.btn-i on-small {
    top: -1px;
    @apply relative mr-1 text-xs !important;
}
.marginpadd-0 {
    @apply mx-0 p-0 !important;
}
.auth-title {
    @apply mb-4 font-acumin-bold text-xl uppercase text-interface-900;
}
button.padding0 {
    @apply p-0 !important;
}
button.textCommonBlack {
    @apply text-common-black;
}
button.text-normal-base {
    @apply text-base !important;
}
/* =============
    Separator 
=============== */
.separator {
    @apply relative mt-4 mb-4 block h-[1px] w-full bg-gray-200;
}
.auth-heading {
    @apply mb-4 font-acumin-bold text-xl uppercase text-interface-900;
}
.vertical-seprator:after {
    content: '';
    @apply absolute right-[-22px] top-0 h-full w-[1px] bg-interface-200;
}
.accordian-active .icon-caret-down {
    @apply inline-block rotate-180;
}
/* =============
    Sticky TOp Header
=============== */
.sticky-top-header {
    @apply sticky top-0 right-0 z-10 mb-6 -ml-[24px] -mr-[24px] h-[56px] w-auto min-w-full items-center  justify-center bg-common-white px-6 shadow mobile:h-[103px];
}
.sticky-top-header .inner {
    @apply flex h-[64px] w-full  items-center pb-[10px] pt-[7px];
}
/* =============
Right panel
=============== */
.right-panel {
    @apply ml-auto w-[calc(100%-260px)] px-6;
}
.w-180 {
    max-width: 180px;
}
.w-850 {
    width: 850px;
}
.w-900 {
    width: 900px;
}
.w-1500 {
    width: 1500px;
}
/* =============
    Separator with text
=============== */
.separator-with-text {
    @apply relative mt-1 mb-5 flex h-5 w-full items-center justify-center;
}
.separator-with-text::after {
    content: '';
    height: 1px;
    @apply absolute top-[4px] left-0 right-0 bottom-0 m-auto w-full bg-interface-200;
}
.separator-with-text span {
    @apply relative z-10 px-2;
}
.custom-underline {
    @apply absolute left-0 right-0 bottom-0.5 h-[1px] w-full bg-primary-500;
}
.card {
    @apply overflow-hidden rounded-lg border border-interface-200 bg-common-white;
}
.card-head {
    @apply border-b border-interface-200 px-4 py-4;
}
.card-body {
    @apply p-4;
} /* =============
    Datepicker
=============== */
.custom-date-time .react-datepicker {
    @apply rounded-lg border-interface-100 bg-common-white shadow;
}
.react-datepicker__month-container {
    @apply relative p-1.5 pt-[80px];
}
.custom-date-time .react-datepicker__header {
    @apply pointer-events-none absolute top-0 left-0 right-0 mx-auto h-full w-full border-none bg-transparent pt-3;
}
.custom-date-time
    .react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown {
    @apply pointer-events-auto;
}
.custom-date-time .react-datepicker__year-read-view--down-arrow,
.custom-date-time .react-datepicker__month-read-view--down-arrow,
.custom-date-time .react-datepicker__month-year-read-view--down-arrow,
.custom-date-time .react-datepicker__navigation-icon::before {
    border-width: 2px 2px 0 0;
    @apply border-common-black hover:border-common-black;
}
.custom-date-time
    .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before,
.custom-date-time
    .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::after {
    @apply border-b-common-white;
}
.custom-date-time
    .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before {
    @apply border-b-interface-100;
}
.react-datepicker__current-month {
    @apply absolute left-4;
}
.react-datepicker__day-names {
    @apply relative mt-6 font-acumin-bold after:absolute after:bottom-0 after:left-0 after:right-0 after:mx-auto after:h-[1px] after:w-[calc(100%-10px)] after:bg-interface-100 after:content-[''];
}
.react-datepicker__navigation.react-datepicker__navigation--previous,
button.react-datepicker__navigation.react-datepicker__navigation--next {
    @apply top-2;
}
.react-datepicker__navigation.react-datepicker__navigation--previous {
    @apply left-auto right-8;
}
.custom-date-time .react-datepicker__day--in-range,
.custom-date-time
    .react-datepicker__day.react-datepicker__day--in-selecting-range {
    @apply bg-primary-400 hover:bg-primary-500;
}
.custom-date-time .react-datepicker__day--keyboard-selected,
.custom-date-time
    .react-datepicker__day--keyboard-selected.react-datepicker__day--selecting-range-start,
.custom-date-time .react-datepicker__day--range-end,
.custom-date-time .react-datepicker__month-text--keyboard-selected,
.custom-date-time .react-datepicker__quarter-text--keyboard-selected,
.custom-date-time .react-datepicker__year-text--keyboard-selected,
.react-datepicker__day.react-datepicker__day--selected {
    @apply bg-primary-500 hover:bg-primary-500;
}
.custom-date-time
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    @apply bg-primary-500 hover:bg-primary-500;
}
.custom-date-time input,
.custom-date-time .react-datepicker-wrapper,
.custom-date-time .react-datepicker__input-container {
    @apply absolute top-0 left-0 right-0 h-full w-full rounded-md border-none bg-none px-3 outline-none;
}
.time-picker-input .custom-date-time input.react-datepicker-time__input {
    cursor: text;
    letter-spacing: -0.6px;
    @apply absolute top-[-42px] left-[-15px] ml-[14px] h-auto
        w-[80px] rounded-md border-none bg-common-white px-3 font-themeFont text-sm text-common-black outline-none !important;
}
.time-picker-input
    .custom-date-time
    input.react-datepicker-time__input::-webkit-calendar-picker-indicator {
    @apply hidden;
}
.time-picker-input .react-datepicker__input-time-container {
    @apply m-0 h-0 text-transparent;
}
.custom-date-time .react-datepicker__day-name,
.custom-date-time .react-datepicker__day,
.custom-date-time .react-datepicker__time-name {
    @apply h-9 w-9 py-[5px];
}
.custom-date-time .icon-section {
    @apply absolute top-0 right-0 flex h-full items-center justify-center px-3.5;
}
.custom-date-time i {
    @apply text-base text-interface-600;
}
.custom-date-time .react-datepicker__month-read-view {
    @apply absolute left-0;
}
.custom-date-time .react-datepicker__year-read-view {
    @apply pointer-events-auto absolute top-[5px] left-0 h-9 w-1/2  bg-common-black opacity-0;
}
.custom-date-time
    .react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll,
.custom-date-time
    .react-datepicker__year-dropdown-container.react-datepicker__year-dropdown-container--scroll {
    @apply absolute top-0 left-0 right-0 mx-auto h-full w-full;
}
.custom-date-time .react-datepicker__year-dropdown {
    @apply pointer-events-auto absolute  left-0  top-0  flex h-full w-full  flex-row-reverse flex-wrap-reverse gap-3 border-none  bg-common-white py-[54px] px-[44px];
}
.custom-date-time
    .react-datepicker__year-dropdown
    .react-datepicker__year-option {
    @apply left-0 flex w-[25%] items-center justify-center border-none bg-common-white p-4 text-center;
}
.year-range .react-datepicker__year-dropdown .react-datepicker__year-option {
    width: auto;
}
.year-range .react-datepicker__year-dropdown {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}
/* DOB Datepicker customization */
.custom-date-time.dobDatePicker .react-datepicker__navigation {
    @apply hidden;
}
/* DOB Datepicker customization end*/
.custom-date-time
    .react-datepicker__year-dropdown
    .react-datepicker__year-option:first-of-type {
    background-position: center;
    background-size: 28px auto;
    @apply absolute left-0 right-0 top-2 h-[40px] w-[94%] bg-interface-100 bg-no-repeat;
}
.custom-date-time
    .react-datepicker__year-dropdown
    .react-datepicker__year-option:last-of-type {
    background-position: center;
    background-size: 28px auto;
    @apply absolute left-0 right-0 bottom-2 h-[40px] w-[94%] bg-interface-100 bg-no-repeat;
}
.custom-date-time
    .react-datepicker__year-dropdown
    .react-datepicker__year-option:first-of-type
    .react-datepicker__navigation {
    @apply top-0  bg-[url('/up-arrow.svg')] bg-center bg-no-repeat pr-[76px];
}
.custom-date-time
    .react-datepicker__year-dropdown
    .react-datepicker__year-option:last-of-type
    .react-datepicker__navigation {
    @apply top-auto bottom-0 bg-[url('/down-arrow.svg')]  bg-center bg-no-repeat pr-[76px];
}
.custom-date-time
    .react-datepicker__year-option.react-datepicker__year-option--selected_year {
    @apply rounded-md bg-primary-500 font-acumin-bold text-common-white;
}
.custom-date-time
    .react-datepicker__year-option.react-datepicker__year-option--selected_year:first-of-type,
.custom-date-time
    .react-datepicker__year-option.react-datepicker__year-option--selected_year:last-of-type {
    @apply text-common-black;
}
.custom-date-time .react-datepicker__year-option--selected {
    @apply hidden;
}
.react-datepicker__day.react-datepicker__day--outside-month {
    @apply text-interface-300;
}
.react-datepicker__day.react-datepicker__day--selected.react-datepicker__day--outside-month {
    @apply text-common-white;
}
.disabled-timepicker .rdtPicker.rdtPicker .rdtTimeToggle {
    @apply hidden;
}
input.left-icon {
    @apply pl-9;
}
.custom-selectbox {
    @apply relative;
}
.react-datepicker-popper.react-datepicker-popper {
    @apply z-50;
}
/* DOB Datepicker customization */
.custom-date-time.dobDatePicker .react-datepicker__month-container {
    @apply pt-0;
}
.custom-date-time.dobDatePicker .react-datepicker__header {
    @apply pointer-events-auto relative top-0;
}
.custom-date-time.dobDatePicker .react-datepicker__current-month {
    @apply relative left-0 top-1 w-auto text-left;
}
.custom-date-time.dobDatePicker .react-datepicker__header__dropdown {
    @apply absolute right-[5px] top-0 pt-3;
}
.custom-date-time.dobDatePicker .react-datepicker__month-dropdown-container,
.custom-date-time.dobDatePicker .react-datepicker__year-dropdown-container {
    @apply m-0 border border-interface-300 px-[2px] py-[2px] text-[15px] outline-none focus:outline-none;
}
.custom-date-time.dobDatePicker
    .react-datepicker__month-dropdown-container
    select,
.custom-date-time.dobDatePicker
    .react-datepicker__year-dropdown-container
    select {
    @apply focus-visible:outline-none;
}
.custom-date-time.dobDatePicker.react-datepicker__day-names {
    @apply mt-[14px];
}
/* DOB Datepicker customization end*/
.carousel.carousel-slider .control-dots li.dot {
    @apply mx-2 h-[8px] w-[8px] rounded-full border-2 border-common-white  bg-transparent opacity-100 shadow-none hover:shadow-none focus-visible:shadow-none;
}
.carousel.carousel-slider .control-dots li.dot.selected {
    @apply border-common-white bg-common-white;
}
.carousel.carousel .control-arrow,
.carousel.carousel.carousel-slider .control-arrow {
    background: rgba(0, 0, 0, 0.2);
    @apply top-[47%]   h-[32px] w-[32px]  rounded-full bg-contain opacity-100 before:hidden after:relative after:left-[4px] after:block after:h-4 after:w-4 after:bg-[url('/arrow.svg')] after:content-[''];
}
.carousel.carousel.carousel-slider .control-arrow.control-prev {
    @apply ml-4 after:left-[2px] after:rotate-180;
}
.carousel.carousel.carousel-slider .control-arrow.control-prev:after {
    content: '\e90c';
    font-size: 13px;
    font-family: 'icomoon';
    transform: rotate(180deg);
    background-image: none;
    position: relative;
    top: 2px;
}
.carousel.carousel.carousel-slider .control-arrow.control-next:after {
    content: '\e90c';
    font-family: 'icomoon';
    font-size: 13px;
    transform: rotate(0deg);
    background-image: none;
    position: relative;
    top: -2px;
}
/*.carousel.carousel.carousel-slider .control-arrow.control-prev:after {
    content: '\e90c';
    font-size: 11px;
    font-family: 'icomoon';
    transform: rotate(90deg);
    background-image: none;
}*/
/*.carousel.carousel.carousel-slider .control-arrow.control-next:after {
    content: '\e90c';
    font-family: 'icomoon';
    font-size: 11px;
    transform: rotate(-90deg);
    background-image: none;
}*/
.carousel .control-next.control-arrow {
    @apply mr-4;
}
.custom-selectbox i {
    @apply absolute top-0 bottom-0 right-5 m-auto flex items-center;
}
.custom-phone-number.custom-date-time .PhoneInputCountry {
    @apply relative top-3 z-30;
}
.custom-phone-number.custom-date-time .PhoneInputInput {
    @apply pl-12;
}
.mobile-input-form .PhoneInputCountrySelect {
    @apply pointer-events-none;
}
.mobile-input-form .PhoneInputCountrySelectArrow {
    @apply hidden;
}
.custom-phone-number.custom-date-time .PhoneInputCountrySelect,
.custom-phone-number.custom-date-time .PhoneInputCountrySelectArrow {
    @apply hidden;
}
.custom-phone-number.custom-date-time .PhoneInputCountryIcon--border {
    @apply border-none shadow;
}
.rc-time-picker input {
    @apply text-sm;
}
.default-react-select.dark input {
    @apply text-common-white !important;
}
.custom-date-time.custom-phone-number.currency-field input {
    @apply pl-3;
}
/* =============
    Dropdown
=============== */
.dropdown-button {
    @apply inline-flex w-full pb-[7px] pt-[1px] text-sm text-interface-600 mobile:text-xs;
}
.dropdown-button.disabled {
    @apply pointer-events-none text-interface-300 !important;
}
.dropdown-menu {
    @apply absolute right-0 mt-2.5 w-56 overflow-hidden rounded-b-md bg-common-white shadow-md focus:outline-none;
}
.dropdown-menu.sub-dropdown-menu {
    @apply right-[-140px];
}
.editor-data a {
    @apply hover:underline;
}
.btn-header-profile span {
    @apply text-common-black no-underline !important;
}
.label-lg label span {
    @apply font-acumin-bold text-lg text-common-black;
}
.booking-action button span {
    @apply uppercase;
}
.dropdown-menu.dropdown-menu a,
.dropdown-menu.dropdown-menu .click-menu {
    @apply px-4 py-2 text-sm font-normal text-common-black hover:bg-primary-500 hover:text-common-white;
}
.dropdown-menu.dropdown-menu a.active,
.dropdown-menu.dropdown-menu .click-menu.active {
    @apply bg-primary-500 font-normal;
}
.dropdown-menu a::after {
    @apply hidden;
}
.dropdown-button i {
    @apply relative top-[2px] left-[6px] text-common-black;
}
.btn-tag i {
    @apply relative mr-2 text-base;
}
.underline-text-only i {
    font-size: 9px;
    @apply mr-1 font-bold;
}
.add-passenger i {
    top: 1px;
    @apply relative text-xs  !important;
}
.btn-header-profile i {
    @apply text-xs;
}
.tabList button i {
    @apply mr-2 text-base;
}
.navigation-link .dropdown-button i {
    font-size: 10px;
    @apply relative top-[6px] left-[6px] text-common-black;
}
.navigation-link .dropdown-button i.white {
    font-size: 12px;
    @apply top-0 left-0 text-common-white;
}
.max-h-220 {
    max-height: 530px;
}
/* =============
    Table
=============== */
.rdt_Table {
    @apply w-full rounded-lg border border-none text-xs font-normal;
}
.rdt_Table .gZWIKX.rdt_TableHeadRow {
    @apply min-h-[35px];
}
.base-table .rdt_Table .rdt_TableRow,
.MjKnz:not(:last-of-type),
.base-table .rdt_Table .cNxGqa:not(:last-of-type) {
    @apply border-none border-b-transparent;
}
.rdt_Table .actions i {
    @apply mr-1 cursor-pointer p-2 text-lg text-primary-500;
}
.customize-radio .circle i,
.customize-radio .circle .icon-check {
    print-color-adjust: exact;
    @apply relative -top-1 left-[0.2px] text-[8px] text-common-white;
}
.btn-header-profile:hover span,
.btn-header-profile:hover i {
    @apply text-common-white !important;
}
.rdt_Table .actions.gray i {
    @apply text-interface-400;
}
.hideHeader .rdt_Table .rdt_TableHeadRow {
    @apply hidden;
}
.base-table.aircraft-option-table .rdt_Table .rdt_TableRow {
    @apply border-b border-solid border-interface-200 bg-interface-50 py-3 even:bg-common-white last-of-type:border-none;
}
.sc-dmRaPn,
.overflow-x-scroll {
    overflow-x: auto;
}
.sc-dmRaPn::-webkit-scrollbar-track,
.overflow-x-scroll::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 51px;
}
.sc-dmRaPn::-webkit-scrollbar,
.overflow-x-scroll::-webkit-scrollbar {
    width: 4px;
    height: 6px;
    background-color: #fff;
    border-radius: 51px;
}
.sc-dmRaPn::-webkit-scrollbar-thumb,
.overflow-x-scroll::-webkit-scrollbar-thumb {
    background-color: #d4d4d4;
    border-radius: 51px;
}
.dPRnBI.dPRnBI {
    @apply break-normal;
}
.eUeqdG {
    @apply text-xs;
}
.table-lg .eUeqdG {
    @apply text-sm;
}
.table-lg .rdt_Table .gZWIKX.rdt_TableHeadRow {
    min-height: 40px;
}
/* =============
    Dashboard Table
=============== */
.dashboard-table .rdt_Table .rdt_TableBody .rdt_TableRow.rdt_TableRow {
    @apply relative min-h-[44px] bg-transparent text-common-black;
}
.dashboard-table .rdt_Table .rdt_TableBody .rdt_TableRow.rdt_TableRow:after {
    content: '';
    @apply absolute bottom-0 left-0 right-0 mx-auto w-[calc(100%-48px)] border-b border-dashed border-interface-200 bg-transparent;
}
.dashboard-table
    .rdt_Table
    .rdt_TableBody
    .rdt_TableRow.rdt_TableRow:last-child:after {
    @apply hidden;
}
/* =============
    Table Placeholder
=============== */
.tabList button {
    @apply relative mr-6 flex h-12 items-center justify-center border-none px-3 py-3 text-sm text-interface-600 outline-none;
}
.radius-tab button {
    @apply mr-0 h-[38px] w-full border-r border-solid border-interface-200 bg-interface-50 text-sm text-interface-600;
}
.tabTimeLine button {
    @apply h-auto p-0;
}
.review-quote-guest-mobile button {
    width: 100% !important;
}
.review-quote-guest-mobile button:nth-child(2) {
    margin: 15px 0 !important
}
.table-placeholder {
    @apply font-redacted transition-all;
}
.table-placeholder .rdt_Table .rdt_TableBody .rdt_TableRow.rdt_TableRow {
    @apply text-interface-200;
}
.table-placeholder
    .rdt_Table
    .rdt_TableHead
    .rdt_TableHeadRow
    .rdt_TableCol.rdt_TableCol {
    @apply text-interface-300;
}
/* =============
    Tabs
=============== */
.tabList {
    @apply flex;
}
.tabList button[aria-selected='true'] {
    @apply font-acumin-bold text-common-black;
}
.tabList button[aria-selected='true']:after {
    content: '';
    @apply absolute bottom-0 h-0.5 w-full bg-common-black;
}
.tabTimeLine button:last-child {
    @apply mr-0;
}
.tabTimeLine button[aria-selected='true'] {
    @apply font-normal text-primary-500;
}
.tabTimeLine button[aria-selected='true']:after {
    @apply bg-primary-500;
}
.radius-tab {
    @apply overflow-hidden rounded-md border border-interface-200;
}
.radius-tab button:last-child {
    @apply border-r-0;
}
.radius-tab button[aria-selected='true']::after {
    @apply hidden;
}
.radius-tab button[aria-selected='true'] {
    @apply bg-common-white;
}
.radius-tab.tab-lg button {
    @apply h-[41px];
}
/* =============
    Card
=============== */
.default-card {
    @apply rounded-lg border border-interface-200 bg-common-white p-4;
}
.default-card-action-pos {
    @apply absolute right-4 top-4 cursor-pointer text-lg text-interface-600;
}
.default-card-action-icon {
    @apply relative cursor-pointer p-3 text-lg text-interface-600;
}
.dashboard-block-heading {
    @apply mb-3 text-sm uppercase;
}
/* =============
    Child inner Left and right section
=============== */
.child-inner-wrapper {
    @apply flex w-full;
}
.child-inner-left {
    @apply mr-6 w-[384px] flex-shrink-0;
}
.child-inner-right {
    @apply ml-auto w-full;
}
.btn-tag {
    @apply relative flex h-[41px] w-full items-center justify-center rounded-md border border-interface-500 bg-interface-600 py-2 px-4 text-interface-200 tablet:mb-4 tablet:justify-start tablet:last-of-type:mb-0;
}
.bookings-rows .row {
    @apply flex w-full border-b border-interface-200 py-4 text-base first-of-type:pt-0 last-of-type:border-none;
}
/* =============
status
=============== */
.status-danger {
    @apply border-primary-500;
    background-color: #fff1f2;
}
/* =============
items table
=============== */
.item-table {
    @apply overflow-hidden rounded-lg border border-interface-200 mobile:mt-6;
}
.item-table li {
    @apply relative flex items-center border-b border-interface-200 bg-interface-50 px-4 py-3 even:bg-common-white last-of-type:border-none;
}
.aircraft-option li {
    @apply bg-interface-50 even:bg-interface-50;
}
.contract-content ul li {
    @apply mb-3 ml-4 list-outside list-disc text-sm;
}
.contract-content ol li {
    @apply mb-3 ml-4 list-outside;
}
.editor-data ul li {
    @apply mb-0.5 list-outside text-sm;
}
.item-table.additionaltab li {
    @apply px-4 py-2;
}
.item-table li.block {
    display: block;
}
.item-table li.total {
    @apply bg-common-black text-common-white;
}
.item-table li.total.grand-total {
    @apply bg-secondary-600;
}
.item-table.invoice-table li:last-child {
    @apply bg-common-black text-common-white;
}
.editor-data p {
    @apply text-sm;
}
.editor-data ul {
    @apply mb-6 ml-6 list-disc;
}
.bLyqNT.bLyqNT,
.eUeqdG.eUeqdG,
.bMgaAx.bMgaAx div:first-child {
    @apply overflow-visible;
}
.btn-modify.btn-modify {
    @apply text-xs;
}
.planwhite {
    @apply text-common-white;
}
/* =============
label
=============== */
.label {
    @apply mb-2 font-acumin-bold text-sm text-common-black;
}
.btn-header-profile {
    @apply w-full items-start justify-start rounded-none px-4 py-2 hover:bg-primary-500 !important;
}
/*
    React Select 
*/
.default-react-select {
    @apply relative;
}
.default-react-select .icon-search {
    @apply text-base text-interface-600;
}
.default-react-select.dark .icon-search {
    @apply text-interface-200;
}
.default-react-select .css-b62m3t-container,
.default-react-select .css-1s2u09g-control,
.default-react-select .css-1pahdxg-control,
.default-react-select .css-b62m3t-container.css-b62m3t-container {
    @apply h-[42px] w-full rounded-md border border-interface-300 bg-common-white pr-0 pl-8 text-sm text-common-black placeholder:text-interface-600 hover:border-interface-300 focus:border-interface-300 focus:shadow-none focus:outline-none !important;
}
.default-react-select .css-b62m3t-container {
    @apply focus-within:border-interface-600 focus:border-interface-600;
}
.default-react-select .customSelectStyle--is-disabled {
    @apply pointer-events-none px-0 opacity-70;
}
.default-react-select
    .customSelectStyle--is-disabled
    .customSelectStyle__placeholder.css-14el2xx-placeholder {
    @apply pl-8;
}
.default-react-select.custom-select .css-b62m3t-container,
.default-react-select.custom-select .css-1s2u09g-control,
.default-react-select.custom-select .css-1pahdxg-control,
.default-react-select.custom-select .css-b62m3t-container.css-b62m3t-container {
    @apply pl-1 !important;
}
.default-react-select.dark .css-b62m3t-container,
.default-react-select.dark .css-1s2u09g-control,
.default-react-select.dark .css-1pahdxg-control,
.default-react-select.dark .css-b62m3t-container.css-b62m3t-container {
    @apply border-interface-500 bg-interface-600 !important;
}
.default-react-select .css-hxdyr0-control,
.default-react-select .css-jtl9jd-control {
    @apply scrollable h-[38px] w-full overflow-y-auto;
}
.default-react-select.dark .css-hxdyr0-control,
.default-react-select.dark .css-jtl9jd-control {
    @apply bg-transparent;
}
.default-react-select.isFormcontrolError .isHasError,
.default-react-select.dark.isFormcontrolError .isHasError {
    @apply border-danger-600 hover:border-danger-600 !important;
}
.default-react-select.dark .css-qc6sy-singleValue {
    @apply text-common-white;
}
.default-react-select .css-26l3qy-menu {
    @apply -left-[2px] w-full overflow-hidden rounded-lg border-none pt-0 shadow;
}
.homeselect .default-react-select .css-26l3qy-menu {
    @apply w-full;
}
.default-react-select .css-4ljt47-MenuList {
    @apply p-0;
}
.default-react-select .css-4ljt47-MenuList::-webkit-scrollbar-track {
    @apply w-[4px] rounded-[50px] bg-interface-50;
}
.default-react-select .css-4ljt47-MenuList::-webkit-scrollbar {
    @apply h-[6px] w-[5px] rounded-[50px] bg-common-white;
}
.default-react-select .css-4ljt47-MenuList::-webkit-scrollbar-thumb {
    @apply rounded-[50px] bg-interface-200;
}
.default-react-select .css-1okebmr-indicatorSeparator svg,
.default-react-select
    .css-tlfecz-indicatorContainer
    .default-react-select
    .css-tlfecz-indicatorContainer
    svg,
.default-react-select.custom-select
    .css-tlfecz-indicatorContainer
    .default-react-select.custom-select
    .css-tlfecz-indicatorContainer
    svg {
    @apply hidden;
}
.default-react-select.dark .css-14el2xx-placeholder {
    @apply text-interface-200;
}
.css-1hb7zxy-IndicatorsContainer {
    @apply hidden !important;
}
.custom-react-option {
    @apply flex w-full items-center px-5 hover:bg-interface-100;
}
.custom-react-option .inner {
    @apply flex w-full;
}
.custom-react-option .inner .title {
    @apply pr-5 text-sm text-common-black;
}
.custom-react-option .inner .description {
    @apply text-xs text-interface-600;
}
.custom-react-option .css-6j8wv5-Input {
    @apply w-full;
}
.default-react-select .custom-react-option.selected-option {
    @apply bg-interface-100;
}
.default-react-select .css-1rhbuit-multiValue,
.default-react-select.dark .css-1rhbuit-multiValue {
    @apply scrollable bg-interface-200;
}
.default-react-select .css-1rhbuit-multiValue .css-xb97g8:hover,
.default-react-select.dark .css-1rhbuit-multiValue .css-xb97g8:hover {
    @apply bg-transparent text-primary-600;
}
.default-react-select .css-1rhbuit-multiValue .css-xb97g8 .css-tj5bde-Svg,
.default-react-select.dark .css-1rhbuit-multiValue .css-xb97g8 .css-tj5bde-Svg,
.default-react-select .css-1rhbuit-multiValue .css-xb97g8 .css-8mmkcg,
.default-react-select.dark .css-1rhbuit-multiValue .css-xb97g8 .css-8mmkcg {
    @apply text-primary-600;
}
/* =============
    Customize Ck Editor
=============== */
.customizeCkeditor {
    @apply mb-6;
}
.customizeCkeditor .ck.ck-toolbar.ck-toolbar_grouping,
.customizeCkeditor
    .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred {
    @apply border-interface-200;
}
.customizeCkeditor .ck.ck-toolbar.ck-toolbar_grouping {
    @apply h-11 bg-common-white px-4;
}
.customizeCkeditor .ck.ck-content.ck-editor__editable {
    @apply p-6;
}
.customizeCkeditor .ck.ck-editor__editable_inline > :first-child {
    @apply mt-0;
}
.customizeCkeditor .ck.ck-editor__editable_inline p {
    @apply mb-6;
}
.editor-data h6 {
    margin-bottom: 0;
}
.react-html5-camera-photo .display-error h1 {
    @apply mb-0 text-xl;
}
.customizeCkeditor .ck.ck-editor__editable_inline h1,
.customizeCkeditor .ck.ck-editor__editable_inline h2,
.customizeCkeditor .ck.ck-editor__editable_inline h3,
.customizeCkeditor .ck.ck-editor__editable_inline h4,
.customizeCkeditor .ck.ck-editor__editable_inline h5,
.customizeCkeditor .ck.ck-editor__editable_inline h6 {
    @apply mb-2;
}
.customizeCkeditor .ck.ck-editor__editable_inline h5 {
    @apply mb-4;
}
.customizeCkeditor .ck.ck-editor__editable_inline h6 {
    @apply mb-1;
}
.customizeCkeditor button.ck.ck-button {
    @apply hover:bg-interface-100 active:bg-interface-100;
}
.customizeCkeditor .ck.ck-editor__editable_inline ul,
.customizeCkeditor .ck.ck-editor__editable_inline ol {
    @apply mb-6;
}
.customizeCkeditor .ck.ck-editor__editable_inline ul li,
.customizeCkeditor .ck.ck-editor__editable_inline ol li {
    @apply mb-3 list-inside;
}
.customizeCkeditor .ck.ck-editor__editable_inline ul {
    @apply list-disc;
}
.customizeCkeditor .ck.ck-editor__editable_inline ol {
    @apply list-inside list-decimal;
}
/* Flight Calendar */
.flight-calender-open .backDrop {
    @apply pointer-events-none fixed  top-0 right-0  z-20 h-full w-[calc(100%-270px)] bg-common-black opacity-25;
}
.flight-calender-open .activityBackDrop {
    @apply right-0 left-auto;
}
.flight-calendar-container {
    @apply fixed left-[261px] z-50 h-screen w-[384px] bg-common-white p-4 shadow;
}
.flight-calendar-container .row {
    @apply flex gap-1;
}
.flight-calendar-container .row-middle {
    @apply items-center;
}
.flight-calendar-container .col {
    @apply flex-grow;
}
.flight-calendar-container .col-center {
    width: calc(100% / 7);
    flex-basis: calc(92% / 7);
    @apply flex-grow-0 justify-center text-center;
}
.flight-calendar-container .col-end {
    @apply justify-end text-right;
}
.calendar {
    @apply relative block w-full pb-4;
}
.calendar .header .icon:first-of-type {
    @apply ml-4;
}
.calendar .header .icon:last-of-type {
    @apply mr-4;
}
.calendar .body .cell {
    @apply relative h-14 translate-x-1 cursor-pointer rounded-lg;
}
.calendar .body .selected {
    @apply bg-primary-500 text-common-white;
}
.calendar .body .cell:last-child {
    @apply border-r-0;
}
.calendar .body .cell .flight-block {
    @apply absolute bottom-0 left-0 top-1 m-auto flex w-full items-center justify-center text-base font-bold;
}
.calendar .body .cell .flight-block .week-set {
    @apply absolute -top-0.5 text-interface-600;
}
.calendar .body .cell .flight-block .number-set {
    @apply relative top-2;
}
.calendar .body .today .flight-block .week-set {
    @apply text-common-white;
}
.calendar .body .selected .flight-block .week-set {
    @apply text-common-white;
}
.calendar .body .cell .bg {
    @apply opacity-0;
}
.calendar .body .col {
    flex-basis: calc(92% / 7);
    width: calc(100% / 7);
    @apply flex-grow-0;
}
.showFlightDetails {
    @apply border-t border-interface-200 pt-4;
}
.showFlightDetails .booking-card-container {
    @apply border-0 bg-secondary-100 p-2;
}
.showFlightDetails .flight-detail-section {
    @apply pt-0;
}
.showFlightDetails .booking-detail {
    @apply pr-0;
}
.showFlightDetails .seperator {
    @apply absolute bottom-5 w-full border-b border-dotted border-gray-200;
}
.associate-names:last-child span {
    @apply hidden;
}
.customize-accordion.payment label span {
    @apply font-acumin-bold text-base;
}
.react-html5-camera-photo > img,
.react-html5-camera-photo > video {
    @apply h-full w-auto !important;
}
.main-slider .carousel .slide img {
    @apply relative h-full w-full border-0 align-top !important;
}
.documents-preview-slide .carousel.carousel .slide > div > span,
.documents-preview-slide .carousel.carousel .slide img {
    @apply relative h-auto w-full !important;
}
.documents-preview-slide .slider-wrapper {
    @apply max-h-[500px] overflow-y-auto !important;
}
.documents-preview-slide .slider-wrapper::-webkit-scrollbar-track {
    @apply rounded-[50px] bg-common-white;
}
.documents-preview-slide .slider-wrapper::-webkit-scrollbar {
    @apply h-[6px] w-[4px] rounded-[50px] bg-common-white;
}
.documents-preview-slide .slider-wrapper::-webkit-scrollbar-thumb {
    @apply rounded-[50px] bg-interface-100;
}
.documents-preview-slide .react-pdf__message {
    @apply flex h-[200px] items-center justify-center;
}
/*
    Full Calender
*/
/*
    Next Previous button
*/
.fc.fc-media-screen {
    @apply relative mb-4;
}
.fc.fc-media-screen .fc-header-toolbar .fc-toolbar-chunk button.fc-prev-button,
.fc.fc-media-screen .fc-header-toolbar .fc-toolbar-chunk button.fc-next-button {
    @apply rounded-tl-md rounded-bl-md border-interface-200 bg-interface-50 px-6 shadow-none outline-none focus-within:shadow-none focus:shadow-none focus-visible:shadow-none;
}
.fc.fc-media-screen .fc-header-toolbar .fc-toolbar-chunk button.fc-next-button {
    @apply relative mr-[-1.5px] rounded-tl-none rounded-bl-none;
}
.fc.fc-media-screen .fc-header-toolbar .fc-toolbar-chunk button.fc-prev-button {
    @apply rounded-tr-none rounded-br-none;
}
.fc.fc-media-screen
    .fc-header-toolbar
    .fc-toolbar-chunk
    button.fc-prev-button
    span,
.fc.fc-media-screen
    .fc-header-toolbar
    .fc-toolbar-chunk
    button.fc-next-button
    span {
    @apply relative -top-0.5;
}
.fc-toolbar-chunk .fc-button-group button:first-child {
    @apply rounded-tl-md rounded-bl-md;
}
.fc-toolbar-chunk .fc-button-group button:last-child {
    @apply rounded-tr-md rounded-br-md;
}
.fc.fc-media-screen .fc-header-toolbar .fc-toolbar-chunk button:last-of-type {
    @apply rounded-tl-none rounded-bl-none rounded-tr-md rounded-br-md;
}
.fc.fc-media-screen .fc-header-toolbar .fc-toolbar-chunk button .fc-icon {
    @apply text-interface-600;
}
.fc.fc-media-screen
    .fc-header-toolbar
    .fc-toolbar-chunk
    button.fc-dayGridMonth-button,
.fc.fc-media-screen
    .fc-header-toolbar
    .fc-toolbar-chunk
    button.fc-timeGridWeek-button,
.fc.fc-media-screen
    .fc-header-toolbar
    .fc-toolbar-chunk
    button.fc-timeGridDay-button,
.fc.fc-media-screen
    .fc-header-toolbar
    .fc-toolbar-chunk
    button.fc-listWeek-button {
    @apply h-[38px] border-interface-200 bg-interface-100 px-6 pt-1 text-interface-600 focus-within:shadow-none focus:shadow-none focus-visible:shadow-none;
}
.fc.fc-media-screen
    .fc-header-toolbar
    .fc-toolbar-chunk
    button.fc-button-active {
    @apply bg-common-white text-common-black;
}
.fc .fc-toolbar-title {
    @apply absolute top-0.5 left-[170px];
}
.fc-daygrid-day-top {
    @apply justify-center bg-interface-100 text-common-black;
}
.fc-view-harness {
    @apply overflow-hidden rounded-tl-xl rounded-tr-xl;
}
.fc-view-harness table thead {
    @apply bg-interface-900;
}
.fc-view-harness.fc-view-harness-active table thead tr th a {
    @apply py-[10px] text-common-white;
}
.fc-view-harness .fc-daygrid-day-events,
a.fc-daygrid-event.fc-daygrid-block-event.fc-h-event.fc-event.fc-event-start.fc-event-end {
    @apply mx-1 mt-1.5;
}
a.fc-daygrid-event.fc-daygrid-block-event.fc-h-event.fc-event.fc-event-start.fc-event-end.fc-event-past,
a.fc-daygrid-event.fc-daygrid-block-event.fc-h-event.fc-event.fc-event-start.fc-event-end {
    @apply rounded-md border-[#005999] bg-[#005999] px-2 py-1.5;
}
td.fc-daygrid-day.fc-day.fc-day-other
    a.fc-daygrid-event.fc-daygrid-block-event.fc-h-event.fc-event.fc-event-start.fc-event-end.fc-event-past {
    @apply border-interface-400 bg-interface-400;
}
.fc-daygrid-day-frame.fc-scrollgrid-sync-inner {
    @apply bg-common-white;
}
/* mobile - input */
.mobile-input-form .PhoneInput {
    @apply h-full;
}
.mobile-input-form .PhoneInput .PhoneInputInput {
    @apply h-full outline-none;
}
/* verification code */
.verification-form {
    @apply mb-4;
}
.verification-form .ReactInputVerificationCode__container {
    width: 100%;
}
.verification-form .ReactInputVerificationCode__item {
    line-height: 38px !important;
    @apply h-10 w-14 text-base font-normal text-interface-600;
}
.verification-form .ReactInputVerificationCode__input {
    @apply font-themeFont font-light;
}
.main-slider .carousel * {
    @apply relative !important;
}
.main-slider .carousel .slide {
    @apply h-[320px];
}
.main-slider button.control-arrow {
    @apply absolute !important;
}
.main-slider .carousel .thumbs-wrapper {
    @apply hidden !important;
}
.main-slider .carousel-status {
    @apply hidden !important;
}
.main-slider .carousel .control-dots {
    @apply absolute ipad:bottom-[17px] !important;
}
.main-slider .carousel .control-dots .dot {
    @apply mx-1.5 border border-common-white bg-transparent shadow-none !important;
}
.main-slider .carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
    @apply bg-common-white !important;
}
.main-slider .carousel button.control-arrow {
    @apply hidden;
}
.main-slider.meet-the-fleet-slider .carousel .slide {
    @apply h-[280px];
}
.carousel-main
    .react-multi-carousel-list
    button.react-multiple-carousel__arrow {
    min-width: 20px;
    min-height: 20px;
}
.carousel-main
    .react-multi-carousel-list
    button.react-multiple-carousel__arrow::before {
    font-size: 9px;
    top: 0px;
}
.carousel-main .react-multiple-carousel__arrow--left {
    left: calc(1% + 10px);
    top: 41%;
    z-index: 0;
}
.carousel-main .react-multiple-carousel__arrow--right {
    right: calc(1% + 10px);
    top: 41%;
    z-index: 0;
}
.fleet-carousel .newsUpdate .img {
    @apply h-[100px];
}
.roundedRightNone {
    @apply rounded-r-none border-r-0;
}
.roundedLeftNone {
    @apply rounded-l-none border-l;
}
.full-block-radio {
    @apply relative -top-0.5;
}
.book-flight-container label {
    @apply hidden ipad:block;
}
.full-block-radio label label,
.css-1wy0on6 {
    @apply hidden;
}
/*
    Accordian
*/
.customize-accordion .accordion__item {
    @apply relative border-b border-interface-200 last-of-type:border-none;
}
.customize-accordion .accordion__button {
    @apply bg-interface-50 px-4 py-3 font-acumin-bold text-sm focus-visible:border-none focus-visible:outline-none;
}
.customize-accordion .accordion__button[aria-expanded='true'] {
    @apply bg-common-white;
}
.customize-accordion .accordion__button[aria-expanded='false']:after {
    content: '\e90d';
    font-family: 'icomoon';
    @apply absolute right-4 top-4 rotate-180 text-xs;
}
.customize-accordion .accordion__button[aria-expanded='true']:after {
    content: '\e90d';
    font-family: 'icomoon';
    @apply absolute right-4 top-4 text-xs;
}
.customize-accordion.payment .accordion__button[aria-expanded='false']:after {
    content: '\e90d';
    font-family: 'icomoon';
    @apply absolute right-4 top-1 rotate-0 text-xs;
}
.customize-accordion.payment .accordion__button[aria-expanded='true']:after {
    content: '\e90d';
    font-family: 'icomoon';
    @apply absolute right-4 top-1 rotate-180 text-xs;
}
.customize-accordion .accordion__panel {
    @apply mb-0 bg-common-white px-10 py-0 font-themeFont text-sm text-interface-600;
}
.customize-accordion.payment {
    @apply border-0;
}
.customize-accordion.payment .accordion__button {
    @apply mb-2 bg-transparent p-0;
}
.customize-accordion.payment .accordion__item {
    @apply relative border-0;
}
.customize-accordion.payment .accordion__item:last-child .accordion__button {
    @apply mb-0;
}
.customize-accordion.payment .accordion__panel {
    @apply p-0 pl-7 text-sm text-common-black;
}
.fixed-min-height {
    min-height: calc(100vh - 175px);
}
.action-btn .font-regular {
    @apply text-sm !important;
}
.popoverpanel {
    @apply absolute -right-5 bottom-12 w-56 rounded-md bg-common-black p-2.5 text-sm text-common-white;
}
.triangle-right {
    border-top: 50px solid transparent;
    border-left: 100px solid #32557f;
    border-bottom: 50px solid transparent;
}
.popoverpanel:after {
    content: '';
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #000;
    @apply absolute left-0 right-0 -bottom-2 m-auto h-0 w-0 rotate-180;
}
.underline-text-only .font-regular {
    @apply underline;
}
body .line-height-20 {
    line-height: 20px;
}
.add-passenger .font-regular {
    @apply text-xs !important;
}
.font-light.font-small {
    @apply font-themeFont !important;
}
.font-medium.font-textmedium {
    @apply text-base !important;
}
.auth-logo.auth-logo-height {
    @apply hidden;
}
.padding-left-right-none {
    @apply px-0 !important;
}
.margin-left-right-none {
    @apply mx-0 !important;
}
.no-border {
    @apply border-0 !important;
}
.auth-left-side {
    @apply flex h-full w-full flex-col items-center justify-between px-16 mobile:px-12;
}
/* @media only screen and (max-height: 772px) and (orientation: landscape) {
    .auth-logo {
        @apply hidden;
    }
    .auth-logo.auth-logo-height {
        @apply absolute top-[30px] left-[60px] block;
    }
    .auth-card-body {
        @apply pl-0 pr-0 !important;
    }
    .auth-card-inner {
        @apply pl-16 pr-16;
    }
    .auth-heading {
        @apply mt-0;
    }
    .auth-left-side {
        @apply mt-[150px]  max-h-[calc(100vh-150px)] overflow-auto;
    }
    .auth-footer-below.auth-footer-below {
        @apply relative mt-[40px] pb-[20px];
    }
} */
/* @media only screen and (max-height: 700px) and (orientation: landscape) { */
/* .auth-logo {
        @apply mt-[200px] !important;
    } */
/* } */
.react-multi-carousel-item {
    @apply overflow-hidden rounded-lg;
}
.react-html5-camera-photo {
    @apply h-full;
}
.react-html5-camera-photo video {
    @apply h-full;
}
.react-html5-camera-photo #container-circles {
    @apply absolute left-0 bottom-0 top-[122%] z-[9] h-[50px] w-[49%] cursor-pointer opacity-0;
}
.react-html5-camera-photo #container-circles #outer-circle {
    @apply left-0 h-[50px] w-full;
}
.react-html5-camera-photo .display-error {
    @apply flex h-full w-full max-w-[100%] items-center justify-center bg-transparent !important;
}
.add-signature-type .react-multiple-carousel__arrow--left {
    @apply left-[4px]  min-h-[20px] min-w-[20px];
}
.add-signature-type .react-multiple-carousel__arrow--left::before {
    @apply text-[9px];
}
.add-signature-type .react-multiple-carousel__arrow--right {
    @apply right-[4px]  min-h-[20px] min-w-[20px];
}
.add-signature-type .react-multiple-carousel__arrow--right::before {
    @apply text-[9px];
}
.add-signature-type .react-multi-carousel-item {
    @apply flex items-center justify-center;
}
.PhoneInputCountryIcon g.PhoneInputInternationalIconGlobe {
    @apply hidden;
}
.custom-select-container .customSelectStyle__control {
    @apply h-[39px] w-[95%] overflow-auto;
}
.custom-select-container .customSelectStyle__control::-webkit-scrollbar-track {
    @apply rounded-[50px] bg-common-white;
}
.custom-select-container .customSelectStyle__control::-webkit-scrollbar {
    @apply h-[6px] w-[4px] rounded-[50px] bg-common-white;
}
.custom-select-container .customSelectStyle__control::-webkit-scrollbar-thumb {
    @apply rounded-[50px] bg-interface-100;
}
.customSelectStyle__indicators.customSelectStyle__indicators {
    @apply hidden;
}
@media only screen and (max-width: 460px) and (orientation: portrait) {
    .auth-logo.auth-logo-height {
        @apply hidden;
    }
}
/*
    nProgress bar
*/
#nprogress .bar {
    @apply bg-primary-500;
}
#nprogress .spinner {
    @apply hidden;
}
/* Tooltip */
.tooltip {
    @apply relative grid text-common-black;
}
.tooltip-content-top {
    transform: translateY(-100%);
}
.tooltip-content-bottom {
    transform: translateY(100%);
}
.tooltip-content {
    @apply invisible absolute -top-[10px] z-[99] w-max min-w-[auto] max-w-[290px] rounded-md bg-interface-700 px-2 py-2 text-sm text-common-white opacity-0 shadow-2xl;
}
.tooltip-content::before {
    content: '';
    @apply absolute aspect-square w-[10px] bg-interface-700;
}
.tooltip-content-top::before {
    transform: translateX(-50%) rotate(45deg);
    @apply -bottom-[5px] left-2/4;
}
.tooltip-content-bottom::before {
    transform: translateX(-50%) rotate(-45deg);
    @apply -top-[5px] left-2/4;
}
.tooltip:hover .tooltip-content {
    transition: opacity 0.1s ease-in;
    @apply visible opacity-100;
}
.map-bg-color {
    background-color: #324769;
}
.show-print-screen {
    @apply opacity-0;
}
.print-header {
    @apply -mt-[80px];
}
/* Tooltip end */
@media print {
    .passenger-list li {
        @apply inline-block w-[33%] !important;
    }
    .no-break {
        page-break-inside: avoid;
    }
    .map-bg-color {
        background-color: #324769 !important;
        print-color-adjust: exact !important;
    }
    .hide-print-screen {
        @apply hidden;
    }
    .show-print-screen {
        @apply opacity-100 !important;
    }
    .print-header {
        @apply mt-4;
    }
    .total {
        background-color: #000 !important;
        print-color-adjust: exact !important;
    }
    .total.grand-total {
        background-color: #4b5563 !important;
        print-color-adjust: exact !important;
    }
}
/*
    customize Radio
*/
.customize-radio {
    @apply relative mb-2 cursor-pointer pl-7;
}
.customize-radio .circle {
    @apply absolute left-0 top-0.5 block h-[16px] w-[16px] rounded-full border border-interface-300 text-center;
}
.customize-radio .label-text {
    @apply font-themeFont text-sm;
}
.customize-radio.active .circle {
    print-color-adjust: exact;
    @apply border-primary-500 bg-primary-500;
}
.modal-scrollable {
    @apply fixed bottom-0 left-0 right-0 z-10 mt-5 w-full overflow-hidden rounded-lg bg-common-white p-6 pt-3;
}
.preferences textarea {
    @apply resize-none;
}

@tailwind utilities;
